import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpClientService } from 'app/shared/service/http-client.service';

import { IBusinessAttribute } from './business-attribute.model';

@Injectable({
  providedIn: 'root',
})
export class BusinessAttributesService {
  constructor(private readonly http: HttpClientService) {}

  getVerticals(): Observable<IBusinessAttribute[]> {
    return this.http.get('business-attributes/verticals');
  }

  getRoles(): Observable<IBusinessAttribute[]> {
    return this.http.get(`business-attributes/roles`);
  }

  getFunctions(): Observable<IBusinessAttribute[]> {
    return this.http.get(`business-attributes/functions`);
  }

  getDisciplines(): Observable<IBusinessAttribute[]> {
    return this.http.get(`business-attributes/disciplines`);
  }
}
